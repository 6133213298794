.link {
    text-decoration: none;
    background-color: rgb(46, 46, 46);
}

.content_mini_title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}