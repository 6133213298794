.link {
    text-decoration: none;
}

.search_link {
    margin: 0 15%;
    width: 70%;
    background-color: #555;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #333;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
}

.search_link:hover {
    background-color: #333;
    color: #fff;
}