.contact-form {
    background-color: #2b2b2b;
    color: #f2f2f2;
    padding: 20px;


}

.contact-form h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact-form label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    background-color: #4a4a4a;
    color: #f2f2f2;
}

.contact-form textarea {
    height: 150px;
}

.contact-form input[type="submit"] {
    background-color: #d35400;
    color: #f2f2f2;
    border: none;
    padding: 10px 20px;

    cursor: pointer;
}

.contact-form input[type="submit"]:hover {
    background-color: #e67e22;
}

.contact-form input[type="radio"] {
    margin-right: 5px;
}

.contact-form .radio-label {
    font-size: 16px;
    margin-right: 10px;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.radio-group {
    background-color: #2b2b2b;
}

