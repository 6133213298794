.footer {
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #000000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

}