.star_score {
    display: inline-block;
    margin: auto;
    height: 30px;

}

.star_score_list {
    width: 100%;
    text-align: center;
}

.star_ave {
    width: 100%;
    font-size: xx-large;
    text-align: center;
    margin: auto;
}


.star_submit_list {
    display: flex;
    /* 要素を横並びに配置 */
    justify-content: center;
    /* 要素を中央に配置 */
    align-items: center;
    /* 要素を中央に配置 */
    height: 30px;
    padding: 10px;
}

.star {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    height: 100%;
}

.star img {
    height: 100%;
}

.star_submit {
    height: 100%;
    cursor: pointer;
    border: 0;
    padding: 0 5px;
    margin: 0px;
    width: 20%;
    font-size: 1%;
    border-radius: 8px;
}

.star_list {
    width: 100%;
    display: flex;
    /* 要素を横並びに配置 */
    justify-content: center;
    /* 要素を中央に配置 */
    align-items: center;
    /* 要素を中央に配置 */
    height: 30px;
    padding: 1px;
    
}