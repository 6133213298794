.back_button,
.submit_button {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: #333;
    border: none;
    cursor: pointer;
}
.back_button {
    width: 150px;
    padding: 8px;
}

.submit_button {
    width: 150px;
    padding: 8px;
    background-color: rgb(0, 58, 116);
}



.back_button:hover,
.submit_button:hover {
    background-color: rgb(0, 128, 255);
}