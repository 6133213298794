main {
    width: 75%;
    margin: auto;
    color: white;
    padding: 6px;
}

body {
    margin: 0%;
    background-image: url('./Img/pattern.jpg');
    background-repeat: repeat;

}

.App {
    background-color: transparent;
}

a,
a:link,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}
