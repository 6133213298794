.news_area {
    height: 695px;
    padding: 6px 25px;
}

.news_title_area {
    display: flex;
}

.news_title {
    width: 60%;

}

.news_date {
    width: 30%;

}