.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 10px;
}

.logo {
    height: 130px;
}

