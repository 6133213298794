.search {

    display: flex;
    border: solid white 6px;
    flex-direction: row;
    position: relative;
}

.search_item {
    width: 20%;
    margin: 1%;
}

.button {
    margin: 1px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 12px;
}

.search_button {
    padding: 1px 18px;
}

.tags {
    height: 60%;
}

.sort {
    height: 15%;
}


.search_item_name {
    text-align: center;
    margin: auto;
}

.icon {
    width: 100%;
}