.title {
    display: flex;
    /* 要素を横並びに配置 */
    justify-content: space-between;
    padding: 0;
    background-color: black;
}

.content_number {
    width: 15%;
}

.content_title {
    width: 100%;
    /* margin-left: 12px;
    margin-right: 12px; */
}

.tag_area {
    margin: 0;
    margin-left: 11%;
}

.title_migihasi {
    width: 25%;
    background-color: black;
}



.main_area {
    width: 116%;
    display: flex;
    background-color: transparent;
}

.content {
    width: 85%;

    background-color: black;
    padding: 6px;
}

.comment_area {
    width: 15%;
    margin: 0 6px;
    background-color: rgb(46, 46, 46);
}

@media screen and (max-width: 1000px) {
    .main_area {
        flex-direction: column;
    }

    .main_area,
    .comment_area {
        width: 100%;
    }

    .comment_area {
        padding: 0px 6px;
        background-color: black;
    }

    .content {
        width: 100%;
    }
}

.img_area {
    background-color: rgb(46, 46, 46);
    display: flex;
    padding: 12px;


}

.resultImg {
    width: 100%;
    position: relative;
}

.left_area {
    width: 30%;

}

.icon_area {
    display: flex;
}

.icon {
    width: 33%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.content_img {
    width: 100%;
    background-color: black;
}

.setumei {
    font-size: larger;
    white-space: pre-wrap;
    padding: 6px;
    font-size: auto;
}

.next_button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    padding: 0;
    width: 6%;
    top: 55%;
    left: 95%;
    transform: translate(-50%, -50%);

}

.next_button img {
    width: 100%;
    padding-bottom: 100%;
}

.movie_area {
    margin: 6px 0;
    margin-top: 12px;
    width: 100%;
    padding: 12px 0;

    background-color: rgb(46, 46, 46);
}

.movie {
    position: relative;
    width: 98%;
    height: 0;
    margin: auto;
    padding-bottom: 56.25%;



}

.movie iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.syousai {
    background-color: rgb(48, 48, 48);
}

/* コメントエリア */
.comment_submit_area {
    display: flex;
    /* 要素を横並びに配置 */
    justify-content: center;
    /* 要素を中央に配置 */
    align-items: center;
    /* 要素を中央に配置 */
    height: 30px;
    padding: 10px;
}

.comment_input {
    height: 24px;
    width: 100%;
}

.comment_submit {
    height: 100%;
    cursor: pointer;
    border: 0;
    padding: 0 5px;
    margin: 0px;
    width: 20%;
    font-size: 1%;
    border-radius: 8px;
}

.value {
    margin: 0;
}

.createdAt {
    text-align: right;
    margin: 0;
}

.comment {
    background-color: white;
    color: black;
    margin: 10px;
    border-radius: 10px;
    padding: 3px;
}

.comments {
    height: 500px;
    overflow-y: auto;
}

#twitter {
    height: 25px;
    display: inline-block;
    text-decoration: none;
    background-color: #1DA1F2;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    
    transition: all 0.3s ease-in-out;
}

#twitter:hover {
    background-color: #0e83c8;
}